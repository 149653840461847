import React from "react";
import {
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
  FilledInput,
} from "@mui/material";
import { Img } from "components";
import { styled } from "@mui/system";
const FormParent = styled(FormControl)({
  marginBottom: 24,
  position: "relative",
  "& .MuiInputAdornment-root": {
    position: "absolute",
    right: "14px",
    top: "50%",
  },
});
const FloatingLabel = styled(InputLabel)({});
const CustomInput = styled(FilledInput)({
  position: "relative",
  marginBottom: "0px",
  width: "100%",
});
const PasswordInput = ({ label, error, required, errorText, onChange }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormParent fullWidth variant="filled" required={required ? true : false}>
      <FloatingLabel htmlFor="Outlined-adornment-password">
        {label}
      </FloatingLabel>
      <CustomInput
        id="Outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        error={error ? true : false}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <Img src="images/V_Eye.svg" alt="eye" />
              ) : (
                <Img src="images/V_Eye_slash.svg" alt="eye" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      {errorText && (
        <span className="text-sm text-red_900 absolute left-0 bottom-[-20px]">
          {errorText}
        </span>
      )}
    </FormParent>
  );
};

export { PasswordInput };
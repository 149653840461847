// import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Text } from "components";
// import defaultImage from "assets/images/user-default.png";


const Breadcrumbs = ({
	crumbs = [],
}) => {
  return (
    <div className="flex flex-row gap-1 items-center justify-start w-auto">
      {crumbs?.length > 0 && crumbs.map((item, index) => {
        return (
          item.path !== ''?
          <Link
            to={item?.path}
            key={`crumb-${index}`}
            className="text-gray_600 text-xs w-auto">
            {item.name} {index < crumbs.length - 1 && '/'}
          </Link>:
          <Text className="text-black_900 text-xs w-auto" key = {`text-${index}`} >
            {item.name} {index < crumbs.length - 1 && '/'}
          </Text>
        );
      })}
    </div>
  );
};

export {Breadcrumbs};
import { createSlice } from "@reduxjs/toolkit";
import addDeleteGetLocalStorage from "global-modules/addDeleteGetLocalStorage";
import jwt_decode from "jwt-decode";
import { STORAGE } from "tutorEnum";

export const token = createSlice({
  name: "token",
  initialState: {
    jwtToken: addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single") ? true : false,
    userData: addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single")?
        jwt_decode(addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single")) : {}
  },
  reducers: {
    changeToken: (state, action) => {
      state.jwtToken = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single") ? true : false;
      state.userData = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single")?
        jwt_decode(addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single")) : {};
      state.currenttoken = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeToken } = token.actions;
export const currentToken = (state) => state.token.jwtToken;
export const currentUserData = (state) => state.token.userData;
export const currentUserToken = (state) => state.token.currenttoken;
export default token.reducer;

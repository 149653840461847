import React from "react";
import SwitchProvider from "@dhiwise/react-switch";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../components/ErrorMessage";
const Switch = ({
  width,
  height,
  onColor,
  offColor,
  onHandleColor,
  offHandleColor,
  value,
  className,
  checkedIcon = <></>,
  uncheckedIcon = <></>,
  errors = [],
  onChange,
}) => {
  const [selected, setSelected] = React.useState(value);
  const handleChange = (val) => {
    onChange?.(val);
  };
  React.useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className={`${className}`}>
      <SwitchProvider
        width={width}
        height={height}
        checked={value}
        onChange={handleChange}
        onColor={onColor}
        offColor={offColor}
        onHandleColor={onHandleColor}
        offHandleColor={offHandleColor}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
      />
      <ErrorMessage errors={errors} />
    </div>
  );
};
Switch.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  onHandleColor: PropTypes.string,
  offHandleColor: PropTypes.string,
  value: PropTypes.bool,
  className: PropTypes.string,
  checkedIcon: PropTypes.node,
  uncheckedIcon: PropTypes.node,
  onChange: PropTypes.func,
};
Switch.defaultProps = {
  width: 32,
  height: 18,
  value: false,
  className: "",
  onColor: "#00a297",
  offColor: "#c4c4c4",
  onHandleColor: "#ffffff",
  offHandleColor: "#ffffff",
  checkedIcon: <></>,
  onChange: () => {},
  uncheckedIcon: <></>,
};
export { Switch };
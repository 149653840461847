import PropTypes from "prop-types";
import { currentLoader } from "redux/reducers/loader";
import { useSelector } from "react-redux";
const variants = {
  FillRed900: "border border-red_900 border-solid bg-red_900 text-white_A700",
  OutlineBlack900: "border border-black_900 border-solid text-black_900",
  FillBlack900:
    "border border-black_900 border-solid bg-black_900 text-white_A700",
  FillGray400:
    "border border-gray_400 border-solid bg-gray_400 text-white_A700",
  FillLightblue50:
    "border border-light_blue_50 border-solid bg-light_blue_50 text-black_900",
  OutlineRed900: "border border-red_900 border-solid bg-white_A700",
  Transparent: "border border-solid  border-transparent text-black_900",
};
const sizes = {
  sm: "py-[5px] px-2 text-sm capitalize",
  md: "text-base py-[11px] px-4 capitalize",
  lg: "px-4 py-[15px] capitalize",
};
const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  variant,
  size,
  disabled,
  ...restProps
}) => {

  const loaderObj = useSelector(currentLoader);

  return (
    <button
      className={`${className} ${(size && sizes[size]) || ""} ${
        (variant && variants[variant]) || ""
      }`}
      disabled={disabled?disabled:loaderObj}
      style={{ backgroundColor: disabled ? "#C4C4C4" : "" }}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    "FillRed900",
    "OutlineBlack900",
    "FillBlack900",
    "FillGray400",
    "FillLightblue50",
    "OutlineRed900",
    "Transparent",
  ]),
};
Button.defaultProps = { className: "", variant: "Transparent", size: "" };
export { Button };

import React, { useEffect, useRef } from "react";
import { Img, Text, Button, Input } from "components";
import { useTranslation } from "react-i18next";
import {
  currentUserData,
  currentToken,
  changeToken,
  currentUserToken,
} from "redux/reducers/token";
import {
  changeChatObject,
  currentChatObject,
  currentChatbox,
  changeChatbox,
  currentCategoryId,
} from "redux/reducers/chatbox";
import {
  quickChatRequest,
  quickChatTutorId,
} from "redux/reducers/quickChatModal";
import { useDispatch, useSelector } from "react-redux";
import { API } from "studentEnum";
import globalRequest from "global-modules/globalRequest";
import { changeSnackbar } from "redux/reducers/snackbar";
import socket from "../../socket";
import { BlockStudentModal } from "modals/BlockStudentModal";
import addDeleteGetLocalStorage from "global-modules/addDeleteGetLocalStorage";
import { STORAGE } from "tutorEnum";
import jwt_decode from "jwt-decode";
import { ConstructionOutlined } from "@mui/icons-material";
import { changeLoader } from "redux/reducers/loader";
import ChatRemainingCreditsModel from "modals/ChatRemainingCreditsModel";

const ChatMessage = ({ closeChat }) => {
  const dispatch = useDispatch();
  const userData = useSelector(currentUserData);
  const currentChatObj = useSelector(currentChatObject);
  const messagesContainerRef = useRef();
  const currentChat = useSelector(currentChatbox);
  const categoryId = useSelector(currentCategoryId);
  const [message_body, setmessage_body] = React.useState("");
  const [message_bodyTextError, setmessage_bodyTextError] = React.useState("");
  const [recipientId, setrecipientId] = React.useState("");
  const [chatRoomId, setchatRoomId] = React.useState("");
  let [chatData, setchatData] = React.useState([]);
  const [groupedMessages, setGroupedMessages] = React.useState([]);
  const [reciver, setReciver] = React.useState({});
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [RemaningCreditModel, setRemaningCreditModel] = React.useState(false);
  const [blockStudentModalOpen, setBlockStudentModalOpen] =
    React.useState(null);
  const [handleScroll, setHandleScroll] = React.useState(false);
  const [isTyping, setIsTyping] = React.useState(false);

  const closeChats = () => {
    dispatch(
      changeChatObject({
        tutorId: 0,
        isSingle: false,
        tutor: null,
      })
    );
    // dispatch(changeChatbox(false));
  };

  const handleError = () => {
    let error = false;

    if (message_body.trim() === "") {
      setmessage_bodyTextError("Message is required");
      error = true;
    }
    const containsEmoji =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u.test(
        message_body
      );
    if (containsEmoji) {
      setmessage_bodyTextError("Emoji is not allowed");
      error = true;
    }
    return error;
  };

  const toggleQuickChat = async () => {
    //dispatch(changeChatbox(!currentChat));
    dispatch(changeLoader(true));
    let url = API.STUDENT.Remaning_Credit(categoryId);
    let responce = await globalRequest("get", url, null, {}, true, true);
    if (responce?.status === "SUCCESS") {
      dispatch(changeLoader(false));
      if (
        responce?.data?.totaltotalHoursleft > 0 ||
        responce?.data?.totalTutorialleft > 0 ||
        responce?.data?.totalLessonleft > 0
      ) {
        dispatch(changeLoader(false));
        dispatch(
          quickChatTutorId(
            currentChatObj?.tutor?.id
              ? currentChatObj?.tutor?.id
              : currentChatObj?.tutorId
          )
        );
        setRemaningCreditModel(false);
        dispatch(quickChatRequest(true));
        closeChats(false);
      } else {
        dispatch(changeLoader(false));
        setRemaningCreditModel(true);
        // dispatch(quickChatRequest(false));
        // closeChats(false);
      }
    } else {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: responce?.message,
        })
      );
    }
  };

  const handleSend = async () => {
    if (message_body.trim() == "") {
      setmessage_body(message_body.trim());
    }
    if (handleError()) return;
    let newMessage = {
      chatRoomId: chatRoomId,
      senderId: userData.id,
      recipientId: recipientId,
      message: message_body,
      createdBy: userData.id,
      createdAt: new Date(),
    };

    let url = API.CHAT.SEND_MESSAGE;
    let data = {
      recipientId: recipientId,
      message: message_body,
    };
    setmessage_body("");
    // let response = await globalRequest("post", url, data, {}, true, true);
    // if (response.status === "SUCCESS") {
    //   var chatRoomId = response.data.chatRoomId;
    //   if(chatData?.length !== 0){
    //   socket.emit("sendMessage", {
    //     senderId: userData.id,
    //     recipientId: recipientId,
    //     chatRoomId: chatRoomId,
    //     message: message_body,
    //     userType: userData.roleType,
    //     isParent: "",
    //   });
    //   }
    //   messagelist();
    //   setGroupedMessages(groupedMessages);
    //   // console.log("groupedMessages", groupedMessages);
    //   setHandleScroll(!handleScroll);
    // } else {
    //   dispatch(
    //     changeSnackbar({
    //       isOpen: true,
    //       state: "error",
    //       message: response?.message,
    //     })
    //   );
    // }

    if (chatRoomId) {
      if (chatData?.length !== 0) {
        // console.log("chatRoomId", chatRoomId , message_body);
        socket.emit("sendMessage", {
          senderId: userData.id,
          recipientId: recipientId,
          chatRoomId: chatRoomId,
          message: message_body,
          userType: userData.roleType,
          isParent: "",
        });
      }
      messagelist();
      setGroupedMessages(groupedMessages);
      setHandleScroll(!handleScroll);
      return;
    }
    let response = await globalRequest("post", url, data, {}, true, true);
    if (response.status === "SUCCESS") {
      setchatRoomId(response.data.chatRoomId);
      if (chatData?.length !== 0) {
        socket.emit("sendMessage", {
          senderId: userData.id,
          recipientId: recipientId,
          chatRoomId: response.data.chatRoomId,
          message: message_body,
          userType: userData.roleType,
          isParent: "",
        });
      }
      messagelist();
      setGroupedMessages(groupedMessages);
      setHandleScroll(!handleScroll);
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: response?.message,
        })
      );
    }
  };

  const messagelist = async () => {
    let urls = API.CHAT.SEND_MESSAGE;
    let data = {
      recipientId: currentChatObj.tutorId
        ? currentChatObj.tutorId
        : currentChatObj.studentId,
      message: "",
    };
    let responses = await globalRequest("post", urls, data, {}, true, true);
    setchatRoomId(responses.data.chatRoomId);
    setIsBlocked(responses.data.isblockedByTutor ? true : false);
    var chatroomid = responses.data.chatRoomId;
    let url =
      API.CHAT.GET_MESSAGE +
      "?chatRoomId=" +
      chatroomid +
      `&userId=${userData?.id}&Type=${userData.roleType}`;
    const response = await globalRequest("get", url, {}, {}, true, true);
    if (response.status === "SUCCESS") {
      dispatch(changeLoader(false));
      chatData = response?.data?.data || [];
      setchatData(chatData);
      groupMessagesByDay(chatData);
    }
    dispatch(changeLoader(false));
  };

  const groupMessagesByDay = (messages) => {
    const groups = [];
    let currentDate = null;

    messages.forEach((message) => {
      const messageDate = new Date(message.createdAt);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (
        currentDate &&
        currentDate.toDateString() === messageDate.toDateString()
      ) {
        groups[groups.length - 1].messages.push(message);
      } else {
        let groupName = null;
        if (messageDate.toDateString() === today.toDateString()) {
          groupName = "Today";
        } else if (messageDate.toDateString() === yesterday.toDateString()) {
          groupName = "Yesterday";
        } else {
          groupName = messageDate.toLocaleDateString();
        }

        groups.push({
          date: groupName,
          messages: [message],
        });

        currentDate = messageDate;
      }
    });
    setGroupedMessages(groups);
  };

  useEffect(() => {
    if (RemaningCreditModel) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [RemaningCreditModel]);

  useEffect(() => {
    if (currentChatObj.tutorId > 0) {
      setReciver(currentChatObj.tutor);
      setrecipientId(
        currentChatObj.tutorId
          ? currentChatObj.tutorId
          : currentChatObj.studentId
      );
      messagelist();
    }
  }, [currentChatObj]);

  React.useEffect(() => {
    if (blockStudentModalOpen === true) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [blockStudentModalOpen]);

  // useEffect(() => {
  //   const handleReceiveMessage = (data) => {
  //     const updatedGroupedMessages = groupedMessages.map((group) =>
  //       group.date === "Today"
  //         ? { ...group, messages: [...group.messages, data] }
  //         : group
  //     );
  //     setGroupedMessages(updatedGroupedMessages);
  //     // console.log("updatedGroupedMessages", updatedGroupedMessages);
  //   };
  //   // Scroll to the end only when a new message is received
  //   messagesContainerRef.current?.scrollIntoView({
  //     behavior: "auto",
  //     block: "end",
  //   });
  //   const handleBlockUnblock = (data) => {

  //   };

  //   socket.on("recieveMessage", handleReceiveMessage);
  //   socket.on("block&UnBlock_get", handleBlockUnblock);

  //   // Cleanup when component unmounts
  //   return () => {
  //     socket.off("recieveMessage", handleReceiveMessage);
  //     socket.off("block&UnBlock_get", handleBlockUnblock);
  //   };
  // }, [groupedMessages, handleScroll]);

  const handleReceiveMessage = (data) => {
    const updatedGroupedMessages = groupedMessages.map((group) =>
      group.date === "Today"
        ? { ...group, messages: [...group.messages, data] }
        : group
    );
    setGroupedMessages(updatedGroupedMessages);
    // console.log("updatedGroupedMessages", updatedGroupedMessages);
  };

  

  useEffect(() => {

    socket.on("recieveMessage", handleReceiveMessage);
    // Cleanup when component unmounts
    return () => {
      socket.off("recieveMessage", handleReceiveMessage);
    };
  });

  useEffect(() => {
    // const handleReceiveMessage = (data) => {
    //   console.log("ReceiveMessage", data);
    //   const updatedGroupedMessages = groupedMessages.map((group) =>
    //     group.date === "Today"
    //       ? { ...group, messages: [...group.messages, data] }
    //       : group
    //   );
    //   setGroupedMessages(updatedGroupedMessages);
    //   // console.log("updatedGroupedMessages", updatedGroupedMessages);
    // };
    // Scroll to the end only when a new message is received
    messagesContainerRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
    const handleBlockUnblock = (data) => {
      if (data?.isBlock === true) {
        setIsBlocked(true);
      }
      if (data?.isBlock === false) {
        setIsBlocked(false);
      }
    };

    // socket.on("recieveMessage", handleReceiveMessage);
    socket.on("block&UnBlock_get", handleBlockUnblock);

    // Cleanup when component unmounts
    return () => {
      socket.off("block&UnBlock_get", handleBlockUnblock);
    };
  }, [groupedMessages, handleScroll]);

  return (
    <>
      <div
        className={`flex flex-col font-proximanova items-end justify-end mx-auto w-auto fixed top-auto bottom-7 sm:bottom-3 right-7 sm:right-3 h-auto z-[99]`}
      >
        <div className="bg-white_A700 flex flex-col items-center justify-start pb-[0px] h-auto w-full max-w-[350px] min-w-[350px] sm:max-w-[290px] drop-shadow-md">
          <div className="bg-gray_50 flex flex-row items-center justify-start p-[11px] w-full">
            <div className="flex flex-row gap-2 items-center justify-start">
              <Img
                onClick={() => {
                  closeChats(false);
                  dispatch(changeChatbox(true));
                }}
                className="h-6 w-6 cursor-pointer"
                src="images/img_arrowleft.svg"
                alt="send"
              />
              <div className="flex flex-row gap-3 items-center justify-start w-[78%]">
                <Img
                  className="h-8 md:h-auto object-cover w-8"
                  src={`${
                    currentChatObj?.tutor?.profile_image
                      ? currentChatObj?.tutor?.profile_image
                      : "images/img_user_black_900.svg"
                  }`}
                  alt="rectangleTwentySix"
                  // type = "profile"
                />
                <Text className="text-base text-black_900">
                  {`${currentChatObj?.tutor?.firstName}`}{" "}
                  {`${currentChatObj?.tutor?.lastName.split("")[0]}`}
                </Text>
              </div>
            </div>
            <div className="ml-auto flex items-center">
              {userData?.roleType === "tutor" && (
                <Button
                  variant="OutlineBlack900 mr-[10px]"
                  className={
                    userData.roleType == "tutor"
                      ? "border border-black text-black"
                      : "border border-black text-black"
                  }
                  size="sm"
                  onClick={() => {
                    setBlockStudentModalOpen(true);
                      dispatch(changeChatbox(false));
                  }}
                >
                  {isBlocked ? "Unblock" : "Block"}
                </Button>
              )}
              {userData?.roleType === "student" && (
                <Button
                  variant={"FillBlack900"}
                  size={"sm"}
                  className="min-w-[100px] sm:min-w-[50%]"
                  onClick={() => {
                    // dispatch(quickChatRequest(true));
                    // closeChats(false);
                    toggleQuickChat();
                  }}
                >
                  Quick Chat
                </Button>
              )}
              <Img
                onClick={() => closeChats(false)}
                src="images/img_close_black_900_24x24.svg"
                className="h-6 w-6 cursor-pointer"
                alt="close"
              />
            </div>
          </div>
          <div
            className="w-full chat-custome-height overflow-auto px-5"
            key={groupedMessages.id}
          >
            {groupedMessages &&
              groupedMessages?.length > 0 &&
              groupedMessages.map((group, index) => (
                <div
                  className="flex flex-col items-center justify-end py-[17px] w-full"
                  key={`chat-${index}`}
                  ref={messagesContainerRef}
                >
                  <div className="time-span py-1 px-3 min-w-[72px] bg-gray_50 inline text-black_900 text-[12px] text-center my-2 mx-auto">
                    {group.date}
                  </div>
                  {group.messages.map((message, messageIndex) => (
                    <div
                      className={`${
                        message.recipientId === recipientId
                          ? "received-msg"
                          : "sent-msg"
                      } py-2 px-3 min-w-[72px] bg-light_blue_50 inline text-black_900 text-sm text-left ${
                        message.senderId === recipientId ? "mr-auto" : "ml-auto"
                      } my-1 max-w-[80%]`}
                      key={`message-${messageIndex}`}
                    >
                      {message.message}
                    </div>
                  ))}
                </div>
              ))}
          </div>
          {isBlocked ? null : (
            <Input
              className="riply-text-input h-56px m-0"
              placeholder="Type here..."
              error={message_bodyTextError !== ""}
              errorText={message_bodyTextError}
              value={message_body}
              required={true}
              onChange={(e) => {
                setmessage_body(e.target.value);
                setmessage_bodyTextError("");
                setIsTyping(true);
              }}
              onBlur={() => setIsTyping(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSend();
                }
              }}
              suffix={
                <Img
                  src="images/img_play-red.svg"
                  className={`w-6 h-6 my-2 absolute right-4 cursor-pointer send-icon ${
                    isTyping ? "typing" : ""
                  }`}
                  alt="play"
                  onClick={(e) => {
                    setIsTyping(false);
                    handleSend();
                  }}
                />
              }
            ></Input>
          )}
        </div>
      </div>
      {blockStudentModalOpen === true ? (
        <BlockStudentModal
          currentStatus={!isBlocked}
          closepopup={setBlockStudentModalOpen}
          onBlockSuccess={() => {
            let blocks = true;
            if (isBlocked === false) {
              blocks = true;
              setIsBlocked(true);
            } else {
              blocks = false;
              setIsBlocked(false);
            }
            if (
              chatRoomId !== null ||
              chatRoomId !== undefined ||
              chatRoomId !== ""
            ) {
              socket.emit("block&UnBlock", {
                recipientId: reciver.id,
                isBlock: blocks,
                name: reciver.name,
                chatRoomId: chatRoomId,
              });
            }
          }}
        ></BlockStudentModal>
      ) : null}

      {RemaningCreditModel === true ? (
        <ChatRemainingCreditsModel closepopup={setRemaningCreditModel} />
      ) : null}
    </>
  );
};

export { ChatMessage };

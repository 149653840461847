import { TextField, FormControl, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
const FormParent = styled(FormControl)({
  position: "relative",
  marginBottom: 24,
});
const CustomInput = styled(TextField)({
  position: "relative",
  marginBottom: "0px",
  width: "100%",
});
const Input = ({
  className,
  label,
  type = "text",
  value,
  placeholder,
  multiline,
  rows,
  maxRows,
  onChange,
  error,
  required,
  errorText,
  rightCount,
  prefix,
  suffix,
  inputProps,
  InputProps,
  autoComplete,
  disabled = false,
  style = {},
  onKeyDown,
}) => {
  return (
    <FormParent fullWidth className={className}>
      {!!prefix && prefix}
      <CustomInput
        id="filled-basic"
        error={error ? true : false}
        required={required ? true : false}
        label={label}
        variant={"filled"}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete="off"
        onChange={onChange}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        inputProps={inputProps}
        //InputProps={InputProps}
        type={type}
        style={style}
        // autoComplete={autoComplete}
        InputProps={{
          ...InputProps,
          onKeyDown: onKeyDown, // Pass the onKeyDown event handler
        }}        
      />
      {!!suffix && suffix}
      {rightCount && (
        <span className="text-sm text-black_900 absolute right-0 bottom-[-20px]">
          {rightCount}
        </span>
      )}
      {errorText && (
        <span className="text-sm text-red_900 relative left-0 bottom-[-2px] mb-[-14px] leading-none	">
          {errorText}
        </span>
      )}
    </FormParent>
  );
};

export { Input };
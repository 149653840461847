import { createSlice } from "@reduxjs/toolkit";

export const maintanance = createSlice({
  name: "maintanance",
  initialState: {
    value: false,
    password: '',
  },
  reducers: {
    changeMaintanance: (state, action) => {
      state.value = action.payload;
    },
    changeMaintanancePassword: (state, action) => {
      state.password = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeMaintanance } = maintanance.actions;
export const { changeMaintanancePassword } = maintanance.actions;
export const currentMaintanance = (state) => state.maintanance.value;
export const currentMaintanancePassword = (state) => state.maintanance.password;
export default maintanance.reducer;

import {Img} from "components";

const StarElement = ({ star }) => {

  return (
    <div className="flex items-center justify-start">
			{
				[1,2,3,4,5]?.map((item, index) => {
					return (
						<Img
							key={`star-${index}`}
							src={index < star ? "images/img_star_orange_300.svg" : "images/img_star_gray_200.svg"}
							className="w-5"
							alt="Star"
						/>
					)
				
				})
			}
    </div>
  )
};


export {StarElement};
import { createSlice } from "@reduxjs/toolkit";

export const RegisterdTutorId = createSlice({
    name: "RegisterdTutorId",
    initialState: {
        id: null,
    },
    reducers: {
        changeID: (state, action) => {
            state.id = action.payload;
          }
    },
});

export const { changeID } = RegisterdTutorId.actions;  // Update Data(dispatch)
export const currrentID = (state) => state.id; // get Data(useSelector)
export default RegisterdTutorId.reducer;
import { Button, Img, Text } from "components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {currentUserData} from  "redux/reducers/token";

const ChatRemainingCreditsModel = ({ closepopup }) => {
  const { t } = useTranslation();
  const userData = useSelector(currentUserData);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:p-4 w-fit h-fit m-auto">
        <div className="relative my-auto mx-auto w-[498px] max-w-[498px] sm:my-0 bg-white">
          <button
            className="z-[99] w-6 h-6 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-0 top-0 mt-3 mr-3"
            onClick={() => closepopup(false)}
          >
            <img src="images/modal_close.svg" alt="close"/>
          </button>
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-cover confetti-bg p-8">
            {/*header*/}
            <div
              className=" relative text-center w-full max-w-[338px] mx-auto"
              // onSubmit={handleSubmit}
            >
              <Text
                className="font-trajanpro text-black_900 mb-4 uppercase"
                variant="h3"
                as="h3"
              >
                {userData?.planId === null ?
                  t("It's time to subscribe"):
                  t("It's time to upgrade")
                }
              </Text>
              <Img
                src="images/medal.png"
                alt="upgrade-img"
                className="w-[71px] h-[auto] mx-auto"
              />
              <Text
                className="font-proximanova text-black_900 text-center mb-6 mt-5"
                variant="body1"
                as="p"
              >
                {userData?.planId === null?
                  t("You cannot book tutorial or buy lessons from any tutor. Subscribe a plan to have more benefits."):
                  t("Please be advised that either you have selected a tutor who is above the category that your subscription is eligible for or you have run out of credit.")}
              </Text>
              <a
                className="mt-2 ease-linear transition-all duration-150 w-[100%] block max-w-[310px] mx-auto"
                variant={"FillBlack900"}
                size="md"
                href="/purchase-plan"
              > 
                {userData?.planId === null?'Subscribe':"Upgrade Now"}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="fixed inset-0 z-40 bg-black-opacity"
        onClick={() => closepopup(false)}
      ></div>
    </>
  );
};
export default ChatRemainingCreditsModel;

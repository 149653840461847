import { Img, Text } from "components";
const NoDataAvailable = ({ text }) => {
  return (
    <div className="bg-white_A700 flex flex-1 flex-col items-center justify-center w-full min-h-[56vh] p-4">
      <Img
        src="images/img_group37323.svg"
        className="h-[180px] w-[180px]"
        alt="group37323"
      />
      <Text
        className="font-trajanpro text-black_900 mt-[27px] text-center font-bold"
        as="h5"
        variant="h5"
      >
        {text}
      </Text>
      
    </div>
  );
};
export { NoDataAvailable };
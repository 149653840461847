import { useState, useEffect } from "react";
import { Img, Input, Line, Text } from "components";
import { ChatMessage } from "./ChatMessage";
import { useNavigate } from "react-router-dom";
import { API } from "studentEnum";
import globalRequest from "global-modules/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "redux/reducers/loader";
import { changeSnackbar } from "redux/reducers/snackbar";
import {
  changeChatbox,
  currentChatbox,
  changeChatObject,
  currentChatObject,
} from "redux/reducers/chatbox";
import { currentUserData } from "redux/reducers/token";
import socket from "socket";
import { Badge } from "@mui/material";
const ChatBox = ({ closeChat }) => {
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  let [chatRoomData, setchatRoomData] = useState([]);
  const [isChatMessageActive, setIsChatMessageActive] = useState(false);
  const currentUser = useSelector(currentUserData);
  let [chatRoomDataAll, setchatRoomDataAll] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [check, setCheck] = useState(false);

  const getchatrooms = async () => {
    dispatch(changeLoader(true));
    chatRoomData = [];
    let url = API.CHAT.GET_CHAT_ROOMS;
    const response = await globalRequest("get", url, {}, {}, true, true);
    if (response?.status === "SUCCESS") {
      chatRoomData = response?.data;
      setchatRoomData(chatRoomData);
      setchatRoomDataAll(chatRoomData);
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: response?.message,
          state: "error",
        })
      );
    }
  };

  const closeChats = async () => {
    dispatch(changeChatbox(false));
  };

  const chatMessage = (action, sender) => {
    dispatch(
      changeChatObject({
        tutorId: sender.id,
        isSingle: true,
        tutor: sender,
      })
    );
    setIsChatMessageActive(!isChatMessageActive);
  };

  const formateLastMessageTimeDay = (date) => {
    // Convert the last_message_timestamp to a Date object
    const lastMessageTimestamp = new Date(date);

    // Get the current date in local time
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

    // Check if the date is today
    if (
      lastMessageTimestamp.getFullYear() === currentDate.getFullYear() &&
      lastMessageTimestamp.getMonth() === currentDate.getMonth() &&
      lastMessageTimestamp.getDate() === currentDate.getDate()
    ) {
      return "today";
    }

    // Check if the date is yesterday
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    if (
      lastMessageTimestamp.getFullYear() === yesterday.getFullYear() &&
      lastMessageTimestamp.getMonth() === yesterday.getMonth() &&
      lastMessageTimestamp.getDate() === yesterday.getDate()
    ) {
      return "yesterday";
    }

    // If the date is not today or yesterday, return the formatted date
    const formattedDate = `${lastMessageTimestamp.getDate()}/${
      lastMessageTimestamp.getMonth() + 1
    }/${lastMessageTimestamp.getFullYear()}`;
    return formattedDate;
  };
  const handleKeyPress = (event) => {
    setSearchTerm(event);
    const filteredData = chatRoomDataAll?.filter(
      (item) =>
        item?.sender?.firstName?.toLowerCase().includes(event.toLowerCase()) ||
        item?.sender?.lastName?.toLowerCase().includes(event.toLowerCase())
    );
    chatRoomData = filteredData;
    setchatRoomData(chatRoomData);
  };
  function truncateMessage(message, maxLength) {
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + " ...";
    } else {
      return message;
    }
  }
  // useEffect(() => {
  //   getchatrooms();
  //   const handleReceiveMessage = (data) => {
  //     let x = [...chatRoomData];
  //     x &&
  //       x.map((item) => {
  //         if (
  //           item.studentId === data.senderId ||
  //           item.tutorId === data.senderId
  //         ) {
  //           item.last_message_in_chat_room = data.message;
  //           item.last_message_timestamp = new Date();
  //         }
  //       });
  //     setchatRoomData(x);
  //   };
  //   socket.on("recieveMessage", handleReceiveMessage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // new code with solved chatroom data problem
  useEffect(() => {
    // getchatrooms();
    // const handleReceiveMessage = (data) => {
    //   setchatRoomData(prevChatRooms => {
    //     if (!prevChatRooms || !Array.isArray(prevChatRooms)) {
    //       // If chatRoomData is null, undefined, or not an array, return an empty array
    //       return [];
    //     }
  
    //     return prevChatRooms.map(item => {
    //       if (
    //         item.studentId === data.senderId ||
    //         item.tutorId === data.senderId
    //       ) {
    //         return {
    //           ...item,
    //           last_message_in_chat_room: data.message,
    //           last_message_timestamp: new Date()
    //         };
    //       }
    //       return item;
    //     });
    //   });
    // };
     
    const handleReceiveMessage = (data) => {
      getchatrooms();
    }
    socket.on("recieveMessage", handleReceiveMessage);
    getchatrooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  
  return (
    <>
      <div
        className={`flex flex-col font-proximanova items-end justify-end mx-auto w-auto fixed top-auto bottom-7 sm:bottom-3 right-7 sm:right-3 h-auto z-[99]`}
      >
        <div className="bg-white_A700 flex flex-col items-center justify-start pb-[0px] h-auto w-full max-w-[350px] min-w-[350px] sm:max-w-[290px] drop-shadow-md">
          <div className="bg-gray_50 flex flex-col items-center justify-end p-[15px] w-full">
            <div className="flex flex-row items-center justify-between w-[97%] md:w-full">
              <Text className="font-semibold text-black_900" variant="body1">
                  Chat
              </Text>
              <Img
                onClick={() => closeChats(false)}
                src="images/img_close_black_900_24x24.svg"
                className="h-6 w-6 cursor-pointer"
                alt="close"
              />
            </div>
          </div>
          <div className="chatSearchBox relative w-full">
            <Input
              type="text"
              placeholder={
                currentUser.roleType == "student"
                  ? "Search by tutor name"
                  : "Search by student name"
              }
              className="h-[48px] mb-0"
              name="searchTerm"
              onChange={(e) => handleKeyPress(e.target.value)}
            />
            <Img
              src="images/img_search.svg"
              className="h-6 w-6 cursor-pointer absolute top-[10px] right-[15px] searchIcon"
              alt="Search"
            />
          </div>

          <div className="flex flex-col items-center justify-start pt-[17px] w-full chat-custome-height overflow-auto">
            {chatRoomData?.length > 0 ? (
              chatRoomData.map((chats) => (
                <>
                  <div
                    key={`chatRoomData-${chats.id}`}
                    className="flex flex-row items-start justify-start w-full px-4 py-3 hover:bg-gray_50 cursor-pointer"
                    onClick={() => {
                      setCheck(!check);
                      chatMessage(true, chats.sender);
                      closeChats(false);
                    }}
                  >
                    <Img
                      type="profile"
                      src={
                        chats?.sender.profile_image
                          ? chats?.sender.profile_image
                          : "images/img_user_black_900.svg"
                      }
                      className="h-10 md:h-auto object-cover w-10"
                      alt="rectangleNineteen"
                    />
                    <div className="flex flex-col items-start justify-start ml-3">
                      <Text
                        className="font-semibold text-black_900"
                        variant="body2"
                      >
                        {chats.sender.firstName}{" "}
                        {chats.sender.lastName.split("")[0]}
                      </Text>
                      <Text
                        className="font-normal mt-1 text-black_900 overflow-hidden whitespace-nowrap overflow-ellipsis"
                        variant="body3"
                      >
                        {truncateMessage(chats.last_message_in_chat_room, 30)}
                      </Text>
                    </div>
                    <Text
                      className="font-semibold ml-auto mt-0.5 text-gray_600 text-right"
                      variant="body3"
                    >
                      {formateLastMessageTimeDay(chats?.last_message_timestamp)}
                    </Text>
                  </div>
                  {/* <Badge color="error" variant="dot" /> */}
                  <Line className="bg-gray_200 h-px w-[89%]" />
                </>
              ))
            ) : (
              <p>Not Found</p>
            )}
          </div>
        </div>
      </div>
      {/* {isChatMessageActive ? (
        <ChatMessage className={``} closeChat={setIsChatMessageActive} />
      ) : null} */}
    </>
  );
};
export { ChatBox };

import React from "react";
import { Text, Img, Switch } from "components";
import { AddAvailabilityModal } from "modals/AddAvailabilityModal";
import { useTranslation } from "react-i18next";
import { convertTimeTo12Hour } from "Helpers";
import { API } from "tutorEnum";
import globalRequest from "global-modules/globalRequest";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "redux/reducers/snackbar";
import { changeLoader } from "redux/reducers/loader";
import AvailabilityOffModal from "modals/AvailabilityOffModal";

const DayAvailability = ({ day, isOpen, time, getAvailability }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [AddAvailabilityOpen, setAddAvailabilityOpen] = React.useState(false);
  const [AvailabilityOffOpen, setAvailabilityOffOpen] = React.useState(false);
  let [dayOpen, setDayOpen] = React.useState(isOpen);
  let [dayTime, setDayTime] = React.useState(time);

  const changeDayOpen = async (value) => {
    dispatch(changeLoader(true));
    if (value) {
      setAddAvailabilityOpen(true);
    } else {
      setAvailabilityOffOpen(true)
    }
    dispatch(changeLoader(false));
  };

  const switchOffDay = async () => {
    const response = await globalRequest(
      "post",
      API.TUTOR.TUTOR_CHANGE_AVAILABILITY,
      { day: day },
      {},
      true,
      true,
    );
    dispatch(
      changeSnackbar({
        message: response.message,
        state: response.status === "SUCCESS" ? "success" : "error",
        isOpen: true,
      })
    );
    if (response.status === "SUCCESS") {
      getAvailability();
    }
  };

  React.useEffect(() => {
    setDayOpen(isOpen);
    setDayTime(time);
  }, [day, isOpen, time]);
  
  React.useEffect(() => {
    if (AddAvailabilityOpen || AvailabilityOffOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [AddAvailabilityOpen, AvailabilityOffOpen]);

  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setAddAvailabilityOpen(false);
        setAvailabilityOffOpen(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="bg-white_A700 border-b border-gray_200_01 border-solid flex flex-row md:gap-5 items-start justify-between p-3 sm:px-0 w-full">
      <div className="my-1 flex">
        <Switch
          value={dayOpen}
          checked={dayOpen}
          onChange={(val) => changeDayOpen(val)}
          checkChange={(val) => changeDayOpen(val)}
          className="flex align-center mr-5"
        />
        <Text className="font-normal text-black_900 capitalize" variant="body2">
          {day}
        </Text>
      </div>
      <div className="flex flex-col items-start justify-between ml-auto w-[49%] sm:w-[52%]">
        <div className="flex flex-row items-start justify-between w-full">
          <div className="flex flex-col">
            {!dayOpen ? (
              <Text className="font-normal my-1 text-black_900" variant="body2">
                {t("closed")}{" "}
              </Text>
            ) : dayTime.length > 0 ? (
              dayTime?.map((time, index) => (
                time?.starttime && time?.endtime ?  <Text
                  key={`${time}-${index}`}
                  className="font-normal my-1 text-black_900"
                  variant="body2"
                >
                  {convertTimeTo12Hour(time.starttime)} -{" "}
                  {convertTimeTo12Hour(time.endtime)}
                </Text> : "-"
              ))
            ) : null}
          </div>
          <Img
            src="images/img_arrowright_black_900.svg"
            className="h-6 w-6 cursor-pointer"
            onClick={() => setAddAvailabilityOpen(true)}
          />
        </div>
      </div>
      {AddAvailabilityOpen === true ? (
        <AddAvailabilityModal
          day={day}
          time={time}
          closepopup={() => {
            setAddAvailabilityOpen(false);
            // getAvailability();
            // dayOpen = true
            // setDayOpen(dayOpen);

          }}
          getUpdatedAvailability={() => {
            getAvailability();
            setAddAvailabilityOpen(false);
          }}
        />
      ) : null}
      {AvailabilityOffOpen === true ? (
        <AvailabilityOffModal
          closepopup={() => {
            dayOpen = true
            setDayOpen(dayOpen);
            setAvailabilityOffOpen(false);
          }}
          confirm={() => {
            switchOffDay();
            setAvailabilityOffOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};

export { DayAvailability };
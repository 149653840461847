import { createSlice } from "@reduxjs/toolkit";

export const quickChatModal = createSlice({
    name: "quickChatModal",
    initialState: {
        value: false,
        tutorId: 0,
    },
    reducers: {
        quickChatRequest: (state, action) => {
            state.value = action.payload;
        },
        quickChatTutorId: (state, action) => {
            state.tutorId = action.payload;
        }

    },
});

// Action creators are generated for each case reducer function
export const { quickChatRequest } = quickChatModal.actions;
export const currentQuickChatRequest = (state) => state.quickChatModal.value;

export const { quickChatTutorId } = quickChatModal.actions;
export const currentQuickChatTutorId = (state) => state.quickChatModal.tutorId;

export default quickChatModal.reducer;

import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";
import { Button, Img, Text } from "components";
import { useTranslation } from "react-i18next";
import { generateHours, convertTimeTo12Hour } from "Helpers";
import { API } from "tutorEnum";
import { useDispatch } from "react-redux";
import globalRequest from "global-modules/globalRequest";
import { changeSnackbar } from "redux/reducers/snackbar";
import { changeLoader } from "redux/reducers/loader";
const hours = generateHours(30);
const FormParent = styled(FormControl)({
  marginBottom: 24,
  position: "relative",
});
const FloatingLabel = styled(InputLabel)({});
const CustomInput = styled(Select)({
  position: "relative",
  marginBottom: "0px",
  width: "100%",
});
const AddAvailabilityModal = ({
  closepopup,
  day,
  time,
  getUpdatedAvailability,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openHours, setOpenHours] = React.useState([
    {
      starttime: "09:00",
      endtime: "17:00",
      starttimeError: "",
      endtimeError: "",
    },
  ]);
  const addTime = () => {
    let lastHour = openHours[openHours.length - 1];
    if (lastHour.endtimeError === "" && lastHour.starttimeError === "") {
      let nextStartTime = hours[hours.indexOf(lastHour.endtime) + 1];
      let nextEndTime = hours[hours.indexOf(nextStartTime) + 1];
      if (nextStartTime === "23:59") { return; }
      setOpenHours([
        ...openHours,
        {
          starttime: nextStartTime,
          endtime: nextEndTime,
          starttimeError: "",
          endtimeError: "",
        },
      ]);
    }
  };
  const timeChange = (e, type, index1) => {
    if (type === "start") {
      let newOpenHours = [...openHours];
      newOpenHours[index1].endtimeError = "";
      const prevEndTime = newOpenHours[index1 - 1]?.endtime || "00:00";
      const startTime = e.target.value;
      const prevEndTimeValue = parseInt(prevEndTime.split(":").join(""));
      const startTimeValue = parseInt(startTime.split(":").join(""));
      const endTime = parseInt(
        newOpenHours[index1].endtime.split(":").join("")
      );
      newOpenHours[index1].starttimeError =
        prevEndTimeValue > startTimeValue || endTime < startTimeValue || startTime === endTime
          ? "Start time is not valid"
          : "";
      newOpenHours[index1].starttime = startTime;
      setOpenHours(newOpenHours);
    } else {
      let newOpenHours = [...openHours];
      let nextStartTime = newOpenHours[index1 + 1]?.startTime;
      newOpenHours[index1].starttimeError = "";
      const endTime = e.target.value;
      const currentStartTimeValue = parseInt(
        newOpenHours[index1].starttime.split(":").join("")
      );
      const endTimeValue = parseInt(endTime.split(":").join(""));
      const nextStartTimeValue = parseInt(nextStartTime?.split(":").join(""));
      newOpenHours[index1].endtimeError =
        currentStartTimeValue > endTimeValue ||
        nextStartTimeValue < endTimeValue || endTime === currentStartTimeValue
          ? "End time is not valid"
          : "";
      newOpenHours[index1].endtime = endTime;
      setOpenHours(newOpenHours);
    }
  };
  const saveTime = async () => {
    let isValid = true;
    dispatch(changeLoader(true));
    let newOpenHours = openHours.map((open) => {
      if (open.starttimeError || open.endtimeError) {
        isValid = false;
      }
      if (open.starttime === "") {
        open.starttimeError = "Start time is required";
        isValid = false;
      }
      if (open.endtime === "") {
        open.endtimeError = "End time is required";
        isValid = false;
      }
      if (open.starttime === open.endtime) {
        open.starttimeError = "Start time and end time cannot be same";
        isValid = false;
      }
      return { starttime: open.starttime, endtime: open.endtime };
    });
    if (isValid) {
      const data = { day, times: newOpenHours };
      const response = await globalRequest(
        "post",
        API.TUTOR.TUTOR_SET_AVAILABILITY,
        data,
        {},
        true,
        true
      );
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: response.message,
          state: response.status === "SUCCESS" ? "success" : "error",
        })
      );
      if (response.status === "SUCCESS") {
        getUpdatedAvailability();
      }
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
    }
  };
  React.useEffect(() => {
    if (time) {
      if (time.length === 0) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        time = [{ starttime: "09:00", endtime: "17:00" }];
      }
      setOpenHours(
        time.map((timeValue) => {
          const startTime =
            timeValue.starttime === "" ? "09:00" : timeValue.starttime;
          const endTime =
            timeValue.endtime === "" ? "17:00" : timeValue.endtime;
          return {
            starttime: startTime,
            endtime: endTime,
            starttimeError: "",
            endtimeError: "",
          };
        })
      );
    }
  }, [time]);

  return (
    <>
      <div className="shadow-lg bg-white max-h-[96vh] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-fit h-fit m-auto">
        <div className="relative my-auto mx-auto w-full max-w-[480px] min-w-[480px] sm:min-w-full sm:my-0">
          {/*content*/}
          <div className="border-0 relative flex flex-col w-full  outline-none focus:outline-none">
            {/*header*/}
            <div className="p-[30px] relative">
              <Text
                className="font-proximanova text-black_900 mb-1 capitalize" variant="h3" as="h3"
              >{day}</Text>
              <Text
                className="font-proximanova text-black_900 mb-0 max-w-[340px]" variant="body1" as="p"
              >{t("setYourAvailableTimeHereAndCheckYour")}</Text>
              <button
                className="w-6 h-6 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-0 top-0 mt-3 mr-3"
                onClick={() => closepopup(false)}
              >
                <Img src="images/modal_close.svg" alt="close" />
              </button>
              <div className="max-h-[350px] pr-2 mr-[-8px]" style={{ overflowY: "auto" }}>
                {openHours
                  ? openHours.map((open, index) => (
                      <div
                        key={`open-${index}`}
                        className="flex sm:flex-wrap flex-row gap-5 items-start justify-start w-full sm:justify-between"
                      >
                        <Text
                          className="font-semibold text-black_900 mt-4 sm:mt-0 sm:order-1"
                          variant="body2"
                        >
                          {t("available")}
                        </Text>
                        <div className="flex gap-5 ml-auto sm:flex-col sm:gap-0 sm:w-full sm:order-3">
                          <div className="w-[120px] sm:w-full">
                            <FormParent fullWidth variant="filled">
                              <FloatingLabel htmlFor="demo-simple-select-filled-label">
                                Open Time
                              </FloatingLabel>
                              <CustomInput
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={open.starttime}
                                onChange={(e) => timeChange(e, "start", index)}
                              >
                                {hours
                                // .filter(
                                //   (hour) => {
                                //     // get all starttime and endtime from openHours that are not current index
                                //     const allStartTimes = openHours.filter((openHour, openHourIndex) => openHourIndex !== index).map((openHour) => openHour.starttime);
                                //     const allEndTimes = openHours.filter((openHour, openHourIndex) => openHourIndex !== index).map((openHour) => openHour.endtime);
                                //     // filter all hours that are not between allStartTimes and allEndTimes
                                //     const isBetween = allStartTimes.filter((startTime, startTimeIndex) => {
                                //       const endTime = allEndTimes[startTimeIndex];
                                //       const startTimeValue = parseInt(startTime.split(":").join(""));
                                //       const endTimeValue = parseInt(endTime.split(":").join(""));
                                //       const hourValue = parseInt(hour.split(":").join(""));
                                //       return hourValue >= startTimeValue && hourValue <= endTimeValue;
                                //     });
                                //     return isBetween.length === 0;
                                //   }
                                // )
                                ?.map((hour, hourIndex) => (
                                  <MenuItem
                                    key={`open-${index}-${hourIndex}`}
                                    value={hour}
                                  >
                                    {convertTimeTo12Hour(hour)}
                                  </MenuItem>
                                ))}
                              </CustomInput>
                              {open.starttimeError && (
                                <span className="text-xs text-red_900 relative left-0 bottom-[0px]">
                                  {open.starttimeError}
                                </span>
                              )}
                            </FormParent>
                          </div>
                          <div className="w-[120px] sm:w-full">
                            <FormParent fullWidth variant="filled">
                              <FloatingLabel htmlFor="demo-simple-select-filled-label">
                                Close Time
                              </FloatingLabel>
                              <CustomInput
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={open.endtime}
                                onChange={(e) => timeChange(e, "end", index)}
                              >
                                {hours
                                // .filter(
                                //   (hour) =>
                                //     hour !== open.starttime &&
                                //     parseInt(hour.split(":").join("")) > parseInt(open.starttime.split(":").join(""))
                                // )
                                ?.map((hour, hourIndex) => (
                                  <MenuItem
                                    key={`close-${index}-${hourIndex}`}
                                    value={hour}
                                  >
                                    {convertTimeTo12Hour(hour)}
                                  </MenuItem>
                                ))}
                              </CustomInput>
                              {open.endtimeError && (
                                <span className="text-xs text-red_900 relative left-0 bottom-[0px]">
                                  {open.endtimeError}
                                </span>
                              )}
                            </FormParent>
                          </div>
                        </div>
                        {openHours.length > 1 ? (
                          <Img
                            src="images/img_trash.svg"
                            className="common-pointer h-5 mt-4 w-5 sm:mt-0 sm:order-2"
                            alt="trash"
                            onClick={() => {
                              let newOpenHours = [...openHours];
                              newOpenHours.splice(index, 1);
                              setOpenHours(newOpenHours);
                            }}
                          />
                        ) : null}
                      </div>
                    ))
                  : null}

                {/* <p className=" text-red_900 text-sm font-proximanova mb-[24px]">
                  {t ('wereSorryButYouveAlreadySelectedThisTime')}
                  </p> */}

                {openHours[openHours.length - 1].endtime !== "23:59" ? (
                  <Button
                    className="mt-1 ease-linear transition-all duration-150 w-full font-semibold	"
                    variant={"OutlineBlack900"}
                    size="md"
                    onClick={() => addTime()}
                  >
                    {t("plusMark_addMore")}
                  </Button>
                ) : null}
              </div>
              <Button
                className="mt-6 ease-linear transition-all duration-150 w-full"
                variant={"FillRed900"}
                size="md"
                onClick={() => saveTime()}
              >
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={() => closepopup(false)}
      ></div>
    </>
  );
};
export { AddAvailabilityModal };
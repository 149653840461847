import { createSlice } from "@reduxjs/toolkit";

export const chatbox = createSlice({
  name: "chatbox",
  initialState: {
    show: false,
    categoryId: null,
    chatObject: {
      tutorId: 0,
      isSingle: false,
      tutor: null
    }
  },
  reducers: {
    changeChatbox: (state, action) => {
      state.show = action.payload;
    },
    changeChatObject: (state, action) => {
      state.chatObject = action.payload;
    },
    changeCategoryId : (state, action) => {
      state.categoryId = action.payload;
    }
  },
});


// Action creators are generated for each case reducer function
export const { changeChatbox } = chatbox.actions;
export const currentChatbox = (state) => state.chatbox.show;
export const { changeChatObject } = chatbox.actions;
export const currentChatObject = (state) => state.chatbox.chatObject;
export const { changeCategoryId } = chatbox.actions;
export const currentCategoryId = (state) => state.chatbox.categoryId;

export default chatbox.reducer;

import { configureStore } from "@reduxjs/toolkit";
import register from "./reducers/tutor/register";
import verifyOtp from "./reducers/tutor/verifyOtp";
import RegisterdTutorId from "./reducers/tutor/RegisterdTutorId";
import snackbar from "./reducers/snackbar";
import quickChatModal from "./reducers/quickChatModal";
import loader from "./reducers/loader";
import token from "./reducers/token";
import chatbox from "./reducers/chatbox";
import maintanance from "./reducers/maintanance";

export default configureStore({
  reducer: {
    register: register,
    verifyOtp: verifyOtp,
    RegisterdTutorId: RegisterdTutorId,
    snackbar: snackbar,
    quickChatModal: quickChatModal,
    loader: loader,
    token: token,
    chatbox: chatbox,
    maintanance: maintanance,
  },
});

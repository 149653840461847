import React from "react";
import { FormControl, TextField, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";
// import { PhoneMask } from "Helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const FormParent = styled(FormControl)({
  marginBottom: 24,
  position: "relative",
});


const MobileInput = ({
  label,
  value,
  onChange,
  error,
  required,
  errorText,
  handleMenuItem,
  menuItemValue,
  prefix,
  suffix,
  countryCodes,
  setMobileNo,
  countryCode,
  setCountryCode,
  setMobileNoError,
  state,
}) => {
  return (
    <FormParent fullWidth className="mobile-input" variant="filled">
      {!!prefix && prefix}
      <PhoneInput
        className="phone-input"
        country={"us"}
        value={value}
        enableLongNumbers={true}
        onKeyDown={(e) => {
          if ((value.length - countryCode.length) >= 12 && e.keyCode !== 8) {
            e.preventDefault();
          }
        }}
        onChange={(value, data) => {
          setCountryCode(data?.dialCode);
          setMobileNo(value);
          setMobileNoError("");
        }}
      />
      {!!suffix && suffix}
      {errorText && (
        <span className="whitespace-nowrap text-[11px] text-red_900 absolute left-0 bottom-[-16px] ">
          {errorText}
        </span>
      )}
    </FormParent>
  );
};

export { MobileInput };

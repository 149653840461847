import React from "react";
import "./styles/color.css";
import "./styles/font.css";
import { hydrate, render } from "react-dom";
import App from "./App";
import "./styles/index.css";
import "./styles/tailwind.css";
import "./styles/custom.scss";
import "./i18n";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import { GOOGLE_SOCIAL_KEY } from "Helpers/Constant";
import { GoogleOAuthProvider } from "@react-oauth/google";

const APP =(
<GoogleOAuthProvider clientId={GOOGLE_SOCIAL_KEY}>
    <Provider store={store}>  
      <App />
    </Provider>
    </GoogleOAuthProvider>
)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(APP, rootElement);
} else {
    render(APP, rootElement);
}
import defaultUserImage from "assets/images/user-default.png";
import placeholderImage from "assets/images/placeholder-image.jpg";
const Img = ({
  className,
  src = "defaultNoData.png",
  alt = "testImg",
  type = "default",
  loading = "lazy",
  ...restProps
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      {...restProps}
      loading={loading}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = type !== "default" ? defaultUserImage : placeholderImage;
      }}
    />
  );
};
export { Img };
import { Button, Text } from "components";
import { useTranslation } from "react-i18next";
import { changeToken } from "redux/reducers/token";
import { useDispatch } from "react-redux";
const BlockStudentModal = ({ closepopup ,onBlockSuccess ,currentStatus}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const LogoutHandler = () => {
    onBlockSuccess()
    closepopup(false)
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:p-4 w-fit h-fit m-auto">
        <div className="relative my-auto mx-auto w-[400px] sm:w-full max-w-400 sm:my-0">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="p-[30px] relative">
              <Text
                className="font-proximanova text-black_900 mb-2"
                variant="h3"
                as="h3"
              >
                {currentStatus?t("Block"):t("Unblock")}
              </Text>
              <Text
                className="font-proximanova text-black_900 mb-6"
                variant="body1"
                as="p"
              >
                {currentStatus?t("blockStudentMessage"):t("unblockStudentMessage")}
              </Text>
              <div className="flex justify-end items-center gap-[20px]">
                <Button
                  className="ease-linear transition-all duration-150 w-[90px]"
                  variant={"FillRed900"}
                  size="sm"
                  onClick={LogoutHandler}
                  >
                    {t("yes")}
                </Button>
                <Button
                  className="ease-linear transition-all duration-150 w-[90px]"
                  variant={"OutlineBlack900"}
                  size="sm"
                  onClick={() => closepopup(false)}
                >
                {t("no")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={() => closepopup(false)}
      ></div>
    </>
  );
};
export { BlockStudentModal };
import io from 'socket.io-client';
import addDeleteGetLocalStorage from "global-modules/addDeleteGetLocalStorage";
import { STORAGE } from "tutorEnum";
import { API_URL, Socket_Port } from 'BaseUrl';
const token = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");

let connectionOptions = {
    transports: ["websocket"],
    pingInterval: 1000 * 60 * 10,
    pingTimeout: 1000 * 60 * 7,
    withCredentials: true, 
    extraHeaders: {
      'authorization': `Bearer ${token}`, // Add your custom header here
    },
    query: {
      'authorization': `Bearer ${token}`, // Add your custom header here
    }
  };

const socket = io(`${API_URL}:${Socket_Port}?authorization=${token}`,connectionOptions)

// const socket = io(`https://api.batterseahouse.co.uk:3004?authorization=${token}`,connectionOptions);

export default socket;
import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  FilledInput,
} from "@mui/material";
import { styled } from "@mui/system";
const FormParent = styled(FormControl)({
  marginBottom: 24,
  position: "relative",
});
const FloatingLabel = styled(InputLabel)({});
const CustomInput = styled(Select)({
  position: "relative",
  marginBottom: "0px",
  width: "100%",
});
const SelectBoxCheckbox = ({
  options,
  multiple,
  label,
  error,
  required,
  errorText,
  MenuProps,
  value,
  onChange,
}) => {
  return (
    <>
      <FormParent fullWidth variant="filled" required={required ? true : false}>
        <FloatingLabel id="demo-multiple-checkbox-label">{label}</FloatingLabel>
        <CustomInput
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={multiple}
          value={value}
          input={<FilledInput />}
          renderValue={(selected) =>
            selected.length > 0
              ? options
                  ?.filter((option) => selected?.includes(option.value))
                  ?.map((option) => option.label)
                  .join(", ")
              : ""
          }
          MenuProps={{
            disableScrollLock: false,
            ...MenuProps,
            style: {
              position: "fixed",
              transformOrigin: "center bottom !important",
              transform: "translate(0px, 0px) !important",
            },
          }}
          // MenuProps={MenuProps}
          onChange={onChange}
          error={error ? true : false}
        >
          {options?.map((option) => (
            <MenuItem key={option?.value} value={option?.value}>
              <Checkbox checked={value?.includes(option?.value)} />
              <ListItemText primary={option.label} className="width-full-350" />
            </MenuItem>
          ))}
        </CustomInput>
        {errorText && (
          <span className="text-sm text-red_900 absolute left-0 bottom-[-20px]">
            {errorText}
          </span>
        )}
      </FormParent>
    </>
  );
};
export { SelectBoxCheckbox };
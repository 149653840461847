import { createSlice } from "@reduxjs/toolkit";

export const verifyOtp = createSlice({
    name: "verifyOtp",
    initialState: {
        otp: null,
    },
    reducers: {
        changeOtp: (state, action) => {
            state.otp = action.payload;
          }
    },
});

export const { changeOtp } = verifyOtp.actions;  // Update Data(dispatch)
export const currentOtp = (state) => state.verifyOtp; // get Data(useSelector)
export default verifyOtp.reducer;
import { useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";
import { changeSnackbar } from "../src/redux/reducers/snackbar";
import {currentQuickChatRequest, quickChatRequest} from '../src/redux/reducers/quickChatModal';
import { useDispatch, useSelector } from "react-redux";
import { currentLoader } from "redux/reducers/loader";
import { currentQuickChatTutorId } from "redux/reducers/quickChatModal";
import { currentUserData, currentToken, changeToken } from "redux/reducers/token";
import { ChatBox } from "components/ChatBox";
import { currentChatbox, currentChatObject } from "redux/reducers/chatbox";
import { ChatMessage } from "./components/ChatBox/ChatMessage";
import Routes from "./Routes";
import TokenRefresh from "Helpers/TokenRefresh";
import Loader from "components/Loader";
import  socket  from "socket";
import RequestChat from "modals/RequestChat";

function App() {
  
  const value = useSelector((state) => state.snackbar.value);
  const tutorId = useSelector(currentQuickChatTutorId)
  const loader = useSelector(currentLoader);
  const dispatch = useDispatch();
  const userData = useSelector(currentUserData);
  const token = useSelector(currentToken);
  const isChatBoxActive = useSelector(currentChatbox);
  const currentChatObj = useSelector(currentChatObject);
  const currentQuickChatModal = useSelector(currentQuickChatRequest);
  const userId = userData?.id;
  const timeDifference = (date1, date2) => {
    return Math.floor((date1.getTime() - date2.getTime()) / 1000);
  };

  const refreshAccessToken = async () => {
    if (token) {
      const timeLeft = timeDifference( new Date(userData.exp * 1000), new Date() );
      if (timeLeft < 200) {
        await TokenRefresh();
        dispatch(changeToken());
      }
    }
  };

  useEffect(() => {
    setInterval(() => {
      refreshAccessToken();
    }, 5000);
    setInterval(() => {
      dispatch(changeToken())
    }, 1000);
    refreshAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      
      socket?.on('error', (error) => {
        socket.disconnect();
      });
      socket?.on('connect', () => {
        if(socket.id!==undefined && socket.id!==null && socket.id!==""){
          socket.emit("login", { userId });
        }
      });
    }else{
      socket?.disconnect();
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  return (
    <>
      <Routes />
      <Snackbar
        open={value?.isOpen}
        vertcle="top"
        horizontal="right"
        onClose={() => { dispatch(changeSnackbar({ ...value, isOpen: false })); }}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => {dispatch(changeSnackbar({ ...value, isOpen: false }));}}
          severity={value?.state}
        >{value?.message}</Alert>
      </Snackbar>
      {loader ? <Loader /> : null}
      {isChatBoxActive ? <ChatBox closeChat={() => {
        dispatch(ChatMessage(true));
      }} /> : null}
      {currentChatObj.isSingle ? <ChatMessage className={``} closeChat={currentChatObj.isSingle} /> : null}
      {/* quickChatModal */}
      {currentQuickChatModal ? (
        <RequestChat
          closepopup={() => dispatch(
            quickChatRequest(false)
          )}
          tutorId={tutorId}
        ></RequestChat>
      ) : null}

    </>
  );
}
export default App;

import { FormControlLabel, Checkbox } from "@mui/material";
const CheckboxCustom = ({
  value,
  label,
  labelPlacement,
  onChange,
  checked,
}) => {
  return (
    <>
      <FormControlLabel
        className="font-proximanova text-black_900  text-sm"
        value={value}
        onChange={onChange}
        control={<Checkbox checked={checked} />}
        label={label}
        labelPlacement={labelPlacement}
      />
    </>
  );
};
export { CheckboxCustom };
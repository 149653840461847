import axios from "axios";
import { STORAGE } from "../tutorEnum"
import addDeleteGetLocalStorage from "./addDeleteGetLocalStorage";
import { API_URL } from "BaseUrl";
const globalRequest = (
  method = "get",
  url,
  data = {},
  options = {},
  token = false,
  isHeader = true
) => {
  // global axios request for post get put and delete
  let headers = {};

  let d = {
    method: method,
    url: API_URL + url,
    ...options,
  };

  let tokenData = {};

  if (token) {
    let token = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");
    tokenData = { authorization: `Bearer ${token}` };
  }

  if (isHeader) {
    headers = {
      "Content-Type": "application/json",
    };
  }

  d.headers = { ...headers, ...tokenData };

  if (data) {
    d.data = data;
  }

  return new Promise((resolve, reject) => {
    axios(d)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        const { response } = err;
        if (response) {
          switch (response?.status) {
            case 401:
              localStorage.clear();
              window.location.href = "/login-student";
              break;
            case 403:
              resolve({ status: 'FORBIDDEN', message: "You don't have permission to access this resource" });  
              break;
            case 404:
              resolve({ status: 'NOT_FOUND', message: "Resource not found" });
              break;
            case 422:
            case 400:
              resolve(response.data);
              break;
            default:
              resolve({ status: 'INTERNAL_SERVER_ERROR', message: "Something went wrong" , data: response.data.data });
              break;
          }
        }else{
          resolve({ status: 'INTERNAL_SERVER_ERROR', message: "Something went wrong" , data: response.data.data });
        }
      });
  });
};

export default globalRequest;


const variantClasses = {
  h1: "font-bold text-[32px] leading-[38.00px] sm:leading-[30px] md:text-[28px] sm:text-[24px]",
  h2: "font-bold text-2xl md:text-[22px] sm:text-xl leading-7",
  h3: "font-semibold text-xl break-normal",
  h4: "font-semibold text-base break-normal",
  h5: "text-xl break-normal",
  h6: "text-base break-normal",
  body1: "text-sm break-normal",
  body2: "text-[14px] break-normal",
  body3: "text-xs break-normal",
  body4: "text-[12px] break-normal",
  body5: "font-semibold text-[10px] break-normal",
};
const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variant && variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};
export { Text };

  export const API = {
    COMMON: {
      Tutor_Common_listing : (list_type) =>`/frontend/auth/common-listing?type=${list_type}`,
      PLAN_CATEGORY: '/frontend/api/plan-category',
      GET_STATIC_PAGE: (slug) => `/frontend/api/get-page/${slug}`,
      GET_FAQS: `/frontend/api/get-faqs`,
      GET_SYSTEM_MAINTENANCE: `/get/system-maintenance`,
    },
    STUDENT_AUTH: {
      Student_ID_Suggestions: `/frontend/auth/studentid-suggestions`,
      Student_Send_register_otp: `/frontend/auth/checkemail_sendotp`,
      Student_Verify_Otp: `/frontend/auth/verify_otp`,
      Student_Registration: `/frontend/auth/student-signup`,
      Forgot_Student_ID : `/frontend/auth/forgot-studentid`,
      Forgot_Password_Student : `/frontend/auth/forgot-password-student`,
      Forgot_Password_StudentID : `/frontend/auth/forgot-password-studentid`,
      GET_Student_Profile_Details : `/frontend/api/student/get-details`,
      UPDATE_Student_Profile_Details : `/frontend/api/student/update-profile`,
      UPDATE_Student_Profile_Image : `/frontend/api/student/update-profile-image`,
      Student_Profile_Change_Email : `/frontend/api/student/sent-email-change-otp`, // on Verfiy Icon and resend Button
      Student_Profile_Verify_Email : `/frontend/api/student/email-change-verify`, // Verify Button
    },
    Rate_And_Review : {
      Review_Rating : `/frontend/api/student/review-rating`,
    },
    Student_Dashbord : {
       Student_Dashbord_Credits : `/frontend/api/student/dashboard-credits`,
       Popular_Tutor : `/frontend/api/popular-tutors-listing`,
    },
    PARENT_AUTH : {
      Set_Parent_Password : `/frontend/api/student/set-parent-password`,
      Check_Parent_Password : `/frontend/api/student/check-parent-password`,
    },
    STUDENT: {
      GET_ALL_PLANS : `/frontend/api/student/plan-listing`,
      GET_ALL_PLANS_LISTING : `/frontend/api/plan-category-list`,
      GET_ALL_CARDS : `/frontend/api/student/card-listing`,
      SAVE_CARD : `/frontend/api/student/card-action`,
      CREATE_SUBSCRIPTION : `/frontend/api/student/create-subscription`,
      GET_SUBSCRIPTION : (order_type,order_by) => `/frontend/api/student/subscription-listing?order_by=${order_by}&order_type=${order_type}`,
      GET_PURCHASE_HOURS : `/frontend/api/student/hour-listing`,
      GET_CARD_LISTING : `/frontend/api/student/card-listing`,
      GET_SESSION_MERITHUB_ID : (classid) => `/frontend/api/student/session-by-merithubid?id=${classid}`,
      PURCHASE_HOURS : `/frontend/api/student/purchase-hours`,
      CANCEL_SUBSCRIPTION: `/frontend/api/student/cancel-subscription`,
      GET_ALL_MY_SESSIONS: (page,departmentId,status,type) => `/frontend/api/student/all-session?page=${page}&departmentId=${departmentId}&status=${status}&type=${type}`,
      GET_ALL_DEPARTMENTS : `/frontend/api/student/all-department`,
      GET_SESSION_SERIES_DETAILS : (booking_slug) => `/frontend/api/student/session-series-detail?booking_slug=${booking_slug}`,
      GET_SESSION_Tutorail_DETAILS : (booking_slug) => `/frontend/api/student/tutorial-detail?booking_slug=${booking_slug}`,
      SESSION_CANCEL_REQUEST : `/frontend/api/student/session-cancel`, 
      Tutoral_Mark_No_Show : `/frontend/api/student/noshow-action`,                               
      Suscription_Upgrade : `/frontend/api/student/subscription-upgrade`,
      Suscription_Downgrade : `/frontend/api/student/subscription-downgrade`,
      Remaning_Credit : (category_id) => `/frontend/api/student/remaning-credits-allcategory?category_id=${category_id}`,
      Get_All_Plans : (plan_id) => `/frontend/api/plan-detail?plan_id=${plan_id}`,
      Get_All_Upgrade_Plans : (planid) => `/api/payment/check-upgrade-amount?planid=${planid}`,
    },
    My_Credit :{
        My_Credit_Listing : (year,month) => `/frontend/api/student/credit-listing?year=${year}&month=${month}`,
    },
    Send_Tip :{
        Send_Tip : `/frontend/api/student/tutor-tips`,
        Tutor_Tip : `/frontend/api/student/tutor-tips`,
        Tip_listing : (page) => `/frontend/api/student/all-tips?page=${page}`,
    },
    FIND_TUTOR: {
      GET_ALL_TUTORS : `/frontend/api/tutor-listing`,
      GET_ALL_DEPARTMENTS : `/frontend/api/all-department`,
      GET_ALL_GROUP_LESSONS : `/frontend/api/group-lessons`,
      GET_TUTOR_PROFILE : `/frontend/api/tutor-detail`,
      GET_TUTOR_DEPARTMENT_LIST : `/frontend/api/tutor-department-list`,
      GET_TUTOR_UPCOMING_LESSONS: `/frontend/api/tutor-series-lesson`,
      GET_LESSON_DETAILS: `/frontend/api/series-detail`,
      REQUEST_GROUP_LESSON: `/frontend/api/request-group-lesson`,
      GET_TUTOR_OCCUPIED_TIME: `/frontend/api/tutor-occupied-time-slot`,
      REQUEST_TUTORIAL: `/frontend/api/request-tutorial`,
      PURCHASE_LESSON: `/frontend/api/request-series-lesson`,
      REQUEST_QUICK_CHAT : `/frontend/api/request-quick-chat`,
      Tutor_Reviews: (tutor_slug) => `/frontend/api/student/review-rating-listing?tutor_slug=${tutor_slug}`,
    },
    Contact_Us : {
      Contact_Us : `/api/contact-us`,
    },
    CHAT :{
      GET_CHAT_ROOMS : `/api/chat/get-chat-rooms`,
      SEND_MESSAGE : `/api/chat/check-chat-room`,
      GET_MESSAGE : `/api/chat/get-chat-messages`,
    }
  };

export const STORAGE = {
  PURCHASE_DATA: '_nbtxbumkeb'
};


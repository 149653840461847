import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/system";
import dayjs from "dayjs";
const FormParent = styled(LocalizationProvider)({
  position: "relative",
  marginBottom: 24,
});
const CustomInput = styled(DatePicker)({
  position: "relative",
  marginBottom: "0px",
  width: "100%",
});
const DatePickerInput = ({
  views,
  label,
  error,
  onChange,
  value,
  required,
  errorText,
  maxDate = null,
  minDate = null,
  disabled = false,
}) => {
  return (
    <>
      <div className="relative mb-6 w-full datepicker-parent">
        <FormParent
          fullWidth
          inputVariant="filled"
          required={required ? true : false}
          dateAdapter={AdapterDayjs}
        >
          <CustomInput
            label={label}
            onChange={onChange}
            value={value !== "" ? dayjs(value) : null}
            // placeholder={value === "" ? label : ""}
            views={views}
            error={error ? true : false}
            disabled={disabled}
            // inputProps={{ "aria-label": "Without label" }}
            maxDate={maxDate ? dayjs(maxDate) : null}
            minDate={minDate ? dayjs(minDate) : null}
            format="DD/MM/YYYY"
          />
          {errorText && (
            <span className="text-sm text-red_900 absolute left-0 bottom-[-20px]">
              {errorText}
            </span>
          )}
        </FormParent>
      </div>
    </>
  );
};

export { DatePickerInput };
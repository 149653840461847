import { useEffect,useState } from "react";
import { Button, Img, Text, Input, SelectBox, DatePickerInput, } from "components";
import { useTranslation } from "react-i18next";
import { API } from "studentEnum";
import { useDispatch } from "react-redux";
import { changeLoader } from "redux/reducers/loader";
import { changeSnackbar } from "redux/reducers/snackbar";
import globalRequest from "global-modules/globalRequest";
import {
  getHoursBetween,
  convertTimeTo12Hour,
  getDateForDatabase,
  getHoursBetweenGreater,
} from "Helpers";
import { getHoursBetween10minsGreater } from "Helpers/Functions";

const daysArray = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const RequestChat = ({ closepopup, tutorId }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let [departmentOptions, setDepartmentOptions] = useState();
  let [openHours, setOpenHours] = useState();
  let [selectedDate, setSelectedDate] = useState('');
  let [selectedTime, setSelectedTime] = useState('');
  let [selectDateError, setSelectDateError] = useState('');
  let [selectTimeError, setSelectTimeError] = useState('');

  let [days, setDays] = useState([
    { day: "monday", isOpen: false, time: [] },
    { day: "tuesday", isOpen: false, time: [] },
    { day: "wednesday", isOpen: false, time: [] },
    { day: "thursday", isOpen: false, time: [] },
    { day: "friday", isOpen: false, time: [] },
    { day: "saturday", isOpen: false, time: [] },
    { day: "sunday", isOpen: false, time: [] },
  ]);

  const handleDateChange = async (date) => {
    dispatch(changeLoader(true));
    const newDateData = new Date(date);
    const day = daysArray[newDateData.getDay()];
    const time = days.find((item) => item.day === day);
    if (time.isOpen) {
      selectedDate = date;
      setSelectedDate(selectedDate);
      const currentDate = getDateForDatabase(new Date());
      let url = `${API.FIND_TUTOR.GET_TUTOR_OCCUPIED_TIME}?tutorId=${tutorId}&date=${getDateForDatabase(date)}`;
      const response = await globalRequest('get', url, {}, {}, true, true);
      let occupiedHours = [];
      if (response.data?.length > 0) {
        occupiedHours = response.data.map((item) => item.booked_time);
      }
      let hours = [];
      time?.time.forEach(element => {
        let hoursGenerated = [];
        if (getDateForDatabase(date) !== currentDate) {
          hoursGenerated = getHoursBetween(element.starttime, element.endtime);
        } else {
          const currentDateTime = new Date();
          let hour =
            currentDateTime.getHours() > 9
              ? currentDateTime.getHours()
              : `0${currentDateTime.getHours()}`;
          let minute =
            currentDateTime.getMinutes() > 9
              ? currentDateTime.getMinutes()
              : `0${currentDateTime.getMinutes()}`;
          const currentTime = `${hour}:${minute}`;
          hoursGenerated = getHoursBetween10minsGreater(
            element.starttime,
            element.endtime,
            currentTime
          );
        }
        hoursGenerated = hoursGenerated.filter((item) => !occupiedHours.includes(item));
        const hourGenerated = hoursGenerated.map((item) => {
          return { label: convertTimeTo12Hour(item), value: item };
        });
        hours = [...hours, ...hourGenerated];
      });
      openHours = hours;
      setOpenHours(openHours);
    } else {
      selectedDate = '';
      setSelectedDate(selectedDate);
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: "This day is not available",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const handleSubmit = async (e) => {
		e.preventDefault();
		
  };

  const setAvailability = (data) => {
    days = [
      { day: "monday", isOpen: data.ismonday, time: data.mondayinputs },
      { day: "tuesday", isOpen: data.istuesday, time: data.tuesdayinputs },
      { day: "wednesday", isOpen: data.iswednesday, time: data.wednesdayinputs },
      { day: "thursday", isOpen: data.isthursday, time: data.thursdayinputs },
      { day: "friday", isOpen: data.isfriday, time: data.fridayinputs },
      { day: "saturday", isOpen: data.issaturday, time: data.saturdayinputs },
      { day: "sunday", isOpen: data.issunday, time: data.sundayinputs },
    ];
    setDays(days);
  };

  const getTutorProfile = async () => {
    dispatch(changeLoader(true));
    const url = `${API.FIND_TUTOR.GET_TUTOR_PROFILE}?tutorId=${tutorId}`
    const response = await globalRequest('get', url, {}, {}, false, true);
    const url2 = `${API.FIND_TUTOR.GET_TUTOR_DEPARTMENT_LIST}?tutorId=${tutorId}`
    const response2 = await globalRequest('get', url2, {}, {}, false, true);
    departmentOptions = [];
    response2.data?.map((item) => {
      departmentOptions.push({ label: item?.departmentName, value: item?.departmentId });
      return item?.departmentName
    });
    setDepartmentOptions(departmentOptions);
    setAvailability(response?.data?.availability)
    
    dispatch(changeLoader(false));
  }

  const handleRequest = async () => {
      let error = false;
      if (selectedDate === '') {
        error = true;
        setSelectDateError("Please select date");
      }
      if (selectedTime === '' || selectedTime === null) {
        error = true;
        setSelectTimeError("Please select time");
      }
      if (error) {
        return;
      }
     let url = API.FIND_TUTOR.REQUEST_QUICK_CHAT;
      const data = {
        tutorId: tutorId,
        chat_date: getDateForDatabase(selectedDate),
        chat_time: selectedTime,
      };
      const response = await globalRequest('post', url, data, {}, true, true);
      if (response?.status==="SUCCESS") {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: response?.status ? "success" : "error",
            message: response?.message,
          })
        );
        closepopup(false);
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: response.message,
          })
        );
      }
  }

  useEffect(() => {
    getTutorProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorId]);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:p-4 w-fit h-fit m-auto">
        <div className="relative my-auto mx-auto w-[460px] sm:w-full max-w-460 sm:my-0">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <form className="p-7 relative" onSubmit={handleSubmit}>
                <button
                  className="w-6 h-6 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-0 top-0 mt-3 mr-3"
                  onClick={() => closepopup(false)}
                > <Img src="images/modal_close.svg" alt="close" /> </button>
                <Text className="font-proximanova text-black_900 mb-1 font-bold" variant="h6" as="h6" >
                Book Quick Chat
                </Text>
                <Text className="font-proximanova text-black_900 mb-5 w-[80%] sm:w-auto" variant="body1" as="p" >
                Pick a date and a time to have a 10-minutes quick chat with the tutor.
                </Text>

                <div className="flex flex-col">
                  <DatePickerInput
                    label="Pick a date*"
                    value={selectedDate}
                    onChange={(date)=>{handleDateChange(date); setSelectDateError('')} }
                    minDate={new Date()}
                    error={selectDateError !== ''}
                    errorText={selectDateError}
                  />
                  <SelectBox
                    className="clock-icon"
                    label="Select time*"
                    options={openHours}
                    value={selectedTime}
                    onChange={(e) => {
                      setSelectedTime(e.target.value);
                      setSelectTimeError('')
                    }}
                    // error={selectTimeError !== ''}
                    errorText={selectTimeError}
                  />
                </div>
                <Button
                  className="mt-2 ease-linear transition-all duration-150 w-[100%] sm:mx-auto sm:block"
                  variant={"FillRed900"} size="md" 
                  onClick={handleRequest}
                > Book </Button>
            </form>

          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={() => closepopup(false)}
      ></div>
    </>
  );
};
export default RequestChat;
import { createSlice } from "@reduxjs/toolkit";

export const register = createSlice({
  name: "register",
  initialState: {
    routeChange: false,
    first_name: null,
    last_name: null,
    mobile_no: null,
    email: null,
    profile_tagline: null,
    profile_description: null,
    // otp: null,
  },
  reducers: {
    changeRegisterData: (state, action) => {
        state.first_name = action?.payload?.first_name;
        state.last_name = action?.payload?.last_name;
        state.mobile_no = action?.payload?.mobile_no;
        state.email = action?.payload?.email;
        state.profile_tagline = action?.payload?.profile_tagline;
        state.profile_description = action?.payload?.profile_description;
        state.countryCode = action?.payload?.countryCode;
        // state.routeChange = action?.payload?.routeChange;
    }
    // changeOtp: (state, action) => {
    //   state.otp = action.payload;
    // }
  },
});

// Action creators are generated for each case reducer function
export const { changeRegisterData } = register.actions;  // Update Data(dispatch)
export const { changeRegisterData2 } = register.actions; 
// export const { changeOtp } = register.actions;  // Update Data(dispatch)
export const currentRegisterData = (state) => state.register; // get Data(useSelector) 

export default register.reducer;

import { useDispatch } from "react-redux";
import { changeSnackbar } from "redux/reducers/snackbar";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

const GoogleLoginBtn = ({ googleResponse }) => {
  const dispatch = useDispatch();
  const handleGoogleLogin = async (response) => {
    var decoded = jwt_decode(response.credential);
    const data = {
      first_name: decoded.given_name,
      last_name: decoded.family_name,
      email: decoded.email,
      social_id: decoded.sub,
      social_type: "google",
    };
    googleResponse(data);
  };
  const handleSocialLoginFailure = (err) => {
    dispatch(
      changeSnackbar({
        isOpen: true,
        status: "error",
        message: "Login failed",
      })
    );
  };
  return (
    <div className="social-google">
      <GoogleLogin
        buttonText="Login"
        onSuccess={handleGoogleLogin}
        onFailure={handleSocialLoginFailure}
        width="380px"
        useOneTap={true}
        theme="outline"
        shape="rectangular"
      />
    </div>
  );
};

export { GoogleLoginBtn };
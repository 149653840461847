import OtpInput from "react-otp-input";
const VerificationInput = ({ onChange, valueInp, error }) => {
  return (
    <div
      className="custom-styles relative"
      style={{
        margin: "0 auto",
      }}
    >
      <OtpInput
        className="w-full"
        value={valueInp}
        onChange={onChange}
        numInputs={4}
        renderSeparator={<span> &nbsp;</span>}
        inputStyle="otp-input"
        renderInput={(props) => <input {...props} />}
      />
      <span className="text-sm text-red_900 absolute left10 bottom-[-22px]">{error}</span>
    </div>
  );
};
export { VerificationInput };
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Editor.css";
const Editor = ({ value, handleEditorChange, error, errorText }) => {
  return (
    <div className="w-full relative">
      <CKEditor
        editor={ClassicEditor}
        data={value}
        style={{ border: error ? "1px solid #EF4444" : "1px solid #E5E5E5" }}
        config={{
          toolbar: ["undo", "redo", "|", "bold", "italic"],
        }}
        onReady={(editor) => {
          // You can store the "editor" and use it when needed.
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleEditorChange(data);
        }}
      />
      {error && (
        <span className="text-sm text-red_900 absolute left10 ">
          {errorText}
        </span>
      )}
    </div>
  );
};

export { Editor };
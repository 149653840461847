export const API = {
  // Tutor API's Sinup
  COMMON: {
    Tutor_Common_listing: (list_type) =>
      `/frontend/auth/common-listing?type=${list_type}`,
  },
  TUTOR_AUTH: {
    Tutor_Register: `/frontend/auth/checkemail_sendotp`, // This is also working for Resend OTP
    Tutor_Verify_Otp: `/frontend/auth/verify_otp`,
    Tutor_Register_stepOne: `/frontend/auth/tutor-register`,
    Tutor_Qualification_listing: `/frontend/auth/tutor-qualification-listing`,
    Tutor_Qualification_Add: `/frontend/auth/qualification-action`,
    Tutor_Language_Add_Update: `/frontend/auth/language-action`,
    Tutor_Language_listing: `/frontend/auth/tutor-language-listing`,
    Tutor_Subject_For_Category: (categoryId) =>
      `/frontend/auth/category-subject-listing?categoryId=${categoryId}`,
    Tutor_Subject_Add_Update: `/frontend/auth/subject-action`,
    Tutor_Subject_Get: `/frontend/auth/tutor-subject-listing`,
    Tutor_Upload_Documents: `/frontend/auth/document-action`,
    // Tutor API's Login
    Tutor_Login: `/frontend/auth/login`,
    Tutor_Forgot_Password: `/frontend/auth/forgot-password`,
    Tutor_Reset_Password: `/frontend/auth/reset-password`,
    REFRESH_TOKEN: `/frontend/auth/refresh-token`,
    Tutor_Change_Password: `/device/api/tutor/change-password`,
  },
  TUTOR: {
    GET_ALL_NEW_REQUEST: `/frontend/api/tutor/all-new-request`,
    GET_ALL_SESSIONS: `/frontend/api/tutor/series-confirm-list`,
    GET_ALL_DRAFT: `/frontend/api/tutor/series-draft-list`,
    GET_SESSION_DETAIL: (seriesId , bookingSlug) =>
      `/frontend/api/tutor/series-detail?seriesId=${seriesId}&bookingSlug=${bookingSlug}`,
    // GET_TUTORIAL_DETAIL : () => `/frontend/api/tutor-detail`
    GET_USED_TIME: (lessonDate) =>
      `/frontend/api/tutor/lessons-alldate?lesson_date=${lessonDate}`,
    Tutor_Payout_Details: `/frontend/api/tutor/get-payout`,
    Tutor_Payout_Add_Update: `/frontend/api/tutor/add-payout`,
    TUTOR_GET_AVAILABILITY: "/frontend/api/tutor/get-availability",
    TUTOR_SET_AVAILABILITY: `/frontend/api/tutor/availability-action`,
    TUTOR_CHANGE_AVAILABILITY: `/frontend/api/tutor/availability-dayoff`,
    Get_Tutorail_Request: (slug) =>
      `/frontend/api/tutor/tutorial-grouplesson-detail?id=${slug}`,
    SESSION_CANCEL_REQUEST: `/frontend/api/tutor/cancel-series`,
    Tutorial_Cancel_Request: `/frontend/api/tutor/tutorial-cancel`,

    // Tutor Profile API's
    Tutor_Edit_GetDetails: `/frontend/api/tutor/get-details`, // Edit Profile
    Tutor_Update_Details: `/device/api/v1/payoutac/update-details`, // Update Payout
    Tutor_GetDetail: `/device/api/v1/payoutac/get-details`, // Payout Details
    Tutor_Edit_UpdateDetails: `/frontend/api/tutor/update-profile`, // Update Profile
    Edit_Profile_Send_Otp: `/frontend/api/tutor/sendotp-tomobile`, // Beside Mobile Number Input Box verify and Resend btn on popup.
    Verify_Otp_OnStripe: `/device/api/v1/payoutac/sendotp-tomobile`, // Verify Button on popup.
    Edit_Profile_Verify_Otp: `/frontend/api/tutor/verify-mobile`, // Verify OTP Button on popup.
    Edit_Profile_Verify_Otp_OnStripe: `/device/api/v1/payoutac/verify-mobile`, // Verify OTP Button on popup.
    Tutor_Document_Get: `/frontend/api/tutor/get-documents`,
    Tutor_Reviews: `/frontend/api/tutor/review-rating-listing`,
    Tutor_Profile_Image_Update: `/frontend/api/tutor/update-profile-image`,
    Tutor_Profile_Verify_Email: `/frontend/api/tutor/email-change-verify`, // Verify Button
    Tutor_Profile_Intro_Video: `/frontend/api/tutor/tutorial-update-video`,
    Tutor_Country_Code: `/frontend/api/tutor/get-country-codes`,
    // createLesson API's
    Tutor_Series_Action: `/frontend/api/tutor/series-action`, // add , edit , view
    Tutor_Lesson_Action: `/frontend/api/tutor/lesson-action`, // add , edit , view
    // GET_ALL_PAST_SESSION : `/frontend/api/tutor/all-past-session`,
    GET_ALL_PAST_SESSION: `/frontend/api/tutor/all-past-session-tutor`,
    Get_Lessons_Details: (seriesId) =>
      `/frontend/api/tutor/series-lessons?seriesId=${seriesId}`,
    Update_Serious_Status: `/frontend/api/tutor/update-series-status`,
    GET_Series_Detail: (seriesId) =>
      `/frontend/api/tutor/series-detail?seriesId=${seriesId}`,
    ACCEPT_REQUEST_GROUP: `/frontend/api/tutor/grouplesson-action`,
    ACCEPT_REQUEST_TUTORIAL: `/frontend/api/tutor/tutorial-chat-action`,
    GET_TUTORIAL_DETAIL: (booking_slug) =>
      `/frontend/api/tutor/tutorial-detail?booking_slug=${booking_slug}`,
    Tutorail_Mark_NoShow: `/frontend/api/tutor/mark-noshow`,
    GET_ALL_CALENDER_DATA: `/frontend/api/tutor/get-calender-data`,
    Get_Payout_Details: (order_by, order_type, month, year, status) =>
      `/frontend/api/tutor/get-payout-list?order_by=${order_by}&order_type=${order_type}&month=${month}&year=${year}&status=${status}`,
    Get_Payout_Detials_list: (slug, order_by, order_type) =>
      `/frontend/api/tutor/get-payout-detail-list?slug=${slug}&order_by=${order_by}&order_type=${order_type}`,
    Participant_list: (series_slug) =>
      `/frontend/api/tutor/get-participants-list?series_slug=${series_slug}`,
    Tutor_Lesson_Reschedule: `/frontend/api/tutor/reschedule-lesson`,
    Tutor_Tutorial_Reschedule: `/frontend/api/tutor/tutorial-reschedule`,
    CHECK_FOR_SESSION: `/frontend/api/tutor/mark-lesson-complete`,
  },
  STUDENT_AUTH: {
    // Student API's
    Admin_login: `/frontend/auth/admin-user-login`,
    Student_ID_Suggestions: `/frontend/auth/studentid-suggestions`,
    Check_Unique_Email: `/frontend/api/student/check-unique-email-studentId`,
    Student_Send_register_otp: `/frontend/auth/checkemail_sendotp`,
    Student_Verify_Otp: `/frontend/auth/verify_otp`,
    Student_Registration: `/frontend/auth/student-signup`,
    Student_Login: `/frontend/auth/student-login`,
    Social_Login_StudentId: `/frontend/auth/parent-student-login`,
  },
  POST: {
    GET_ALL_DEPARTMENT: `/frontend/api/get-post-departments`,
    CREATE_POST: `/frontend/api/create-post`,
    GET_DEPARTMENT_POST: (department) =>
      `/frontend/api/get-posts/${department}`,
    GET_DEPARTMENT: (slug) => `/frontend/api/get-department/${slug}`,
    LIKE_DISLIKE_POST: (postSlug) =>
      `/frontend/api/create-post-like/${postSlug}`,
    COMMENT_POST: (postSlug) => `/frontend/api/create-post-comment/${postSlug}`,
    DELETE_POST: (postSlug) => `/frontend/api/delete-post/${postSlug}`,
    DELETE_COMMENT: (commentId) =>
      `/frontend/api/delete-post-comment/${commentId}`,
    REPORT_POST: (postId) => `/frontend/api/report-post/${postId}`,
    GET_TOP_TUTORS: `/frontend/api/top-tutor-listing`,
    GET_TOP_LESSONS: (limit) =>
      `/frontend/api/group-lessons?page=1&limit=${limit}`,
  },
};

export const STORAGE = {
  TOKEN: "nc1P98LKd",
  CHANGE_REGISTER_DATA: "changeRegisterData",
  CHANGE_OTP: "changeOtp",
  CAN_SEE_THANKYOU: "canSeeThankyou",
};
export default API;

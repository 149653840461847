import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect } from "react";
const FormParent = styled(FormControl)({
  marginBottom: 24,
  position: "relative",
});
const FloatingLabel = styled(InputLabel)({});
const CustomInput = styled(Select)({
  position: "relative",
  marginBottom: "0px",
  width: "100%",
});
const SelectBox = ({
  options,
  multiple = false,
  label,
  value,
  onChange,
  error,
  required,
  errorText,
  className,
  MenuProps,
  canSelectDisabledOption = false,
}) => {
  return (
    <>
      <FormParent
        fullWidth
        variant="filled"
        error={error ? true : false}
        required={required ? true : false}
        className={className}
      >
        <FloatingLabel htmlFor="demo-simple-select-filled-label">
          {label}
        </FloatingLabel>
        <CustomInput
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={value}
          onChange={onChange}
          multiple={multiple}
          MenuProps={{
            disableScrollLock: false,
            ...MenuProps,
            style: {
              position: "fixed",
              transformOrigin: "center bottom !important",
              transform: "translate(0px, 0px) !important",
            },
          }}
        >
          {options?.map((option) => (
            <MenuItem key={`option-${option.value}`} value={option.value}
              // eslint-disable-next-line eqeqeq
              disabled={(option.value === "" || option.value === null || option.value == "0") && !canSelectDisabledOption}
            >
              {option.label}
            </MenuItem>
          ))}
        </CustomInput>
        {errorText && (
          <span className="text-sm text-red_900 absolute left-0 bottom-[-20px]">
            {errorText}
          </span>
        )}
      </FormParent>
    </>
  );
};
export { SelectBox };
import { Button, Text, Img } from "components";
import { useTranslation } from "react-i18next";
const AvailabilityOffModal = ({ closepopup, confirm }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="shadow-lg bg-white max-h-[96vh] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-fit h-fit m-auto">
        <div className="relative w-auto my-auto mx-auto max-w-400 min-w-[400px] sm:min-w-full sm:my-0">
          {/*content*/}
          <div className="border-0 relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="p-6 relative">
              <button
                className="w-6 h-6 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-0 top-0 mt-3 mr-3"
                onClick={() => closepopup(false)}
              >
                <Img src="images/modal_close.svg" alt="close" />
              </button>
              <Text
                className="font-proximanova text-black_900 mb-2"
                variant="h3"
                as="h3"
              >
                {t("availabilityOff")}
              </Text>
              <Text
                className="font-proximanova text-black_900 mb-5"
                variant="body1"
                as="p"
              >
                {t("availability_text-large")}
              </Text>
              <div className="flex justify-end items-center gap-3 mt-0">
                <Button
                  className="ease-linear transition-all duration-150 w-full"
                  variant={"FillRed900"}
                  size="md"
                  onClick={confirm}
                >
                  {t("confirm")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={() => closepopup(false)}
      ></div>
    </>
  );
};
export default AvailabilityOffModal;